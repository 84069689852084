<template>
  <!--问题回答详情-->
  <div>
    <div>
      <Header></Header>
    </div>
    <div id="admindiv1">
      <div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13em;margin-left: 10px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 1.2em" :to="{ path: '/expert_index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 1.2em;color: black">有问必答</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!--  问题详情-->
    <div style="width: 95%;margin: auto;">
      <div style="background-color: #FFFFFF;padding-bottom: 5%">
        <div style="display: flex">
          <div style="margin-left: 2%;font-family: '苹方', serif;font-weight: bolder;font-size: 1.5rem;padding-top: 1%">
            {{ questionTitle }}
          </div>
          <div style="margin-top: 1.3%;margin-left: 2%">
            <span style="font-size: 0.6em;">提问人&nbsp;:&nbsp;{{ question_username }} &nbsp;&nbsp; 提问时间&nbsp;:&nbsp;{{
                question_time
              }}</span>
          </div>
          <div style="position: absolute;right: 5%;top: 14%">
            <el-button type="primary" @click="addAnswerDialog = true">✍写回答&nbsp;&nbsp;&nbsp;</el-button>
            <el-button v-if="question_userid === GET_USERID" type="warning" @click="delQuestion()">❌ 删除问题&nbsp;&nbsp;&nbsp;</el-button>
          </div>
        </div>
        <div v-html="question" style="margin-left: 3%;font-family: '苹方', serif;font-weight: bolder;font-size: 1.2rem;padding-top: 3%">
        </div>
        <!--  问题附件-->
        <div style="margin-top: 1%;margin-left: 3%;display: flex;flex-wrap: wrap">
          <div v-if="this.$route.params.fileNum > this.materialUrl.length" style="display: flex">
            <div>
              <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/uploading.png"/>
            </div>
            <div style="margin-top: 40%;">
              <span style="color: red">附件正在上传中.....</span>
            </div>

          </div>
          <div v-else v-for="item in materialUrl" style="margin-left: 2%;margin-top: 1%">
            <img v-if="item.questionMaterialUrl.endsWith('jpg')" :src="item.questionMaterialUrl"
                 style="width: 300px;height: 300px"/>
            <img v-if="item.questionMaterialUrl.endsWith('png')" :src="item.questionMaterialUrl"
                 style="width: 300px;height: 300px"/>
            <video width="300" height="300" controls v-if="item.questionMaterialUrl.includes('mp4')">
              <source :src="item.questionMaterialUrl" type="video/mp4">
            </video>
            <div
                v-if="!item.questionMaterialUrl.includes('jpg') && !item.questionMaterialUrl.includes('png') && !item.questionMaterialUrl.includes('mp4')"
                style="margin-top: 60px"
                @dblclick="openUrl(item.questionMaterialUrl)">
              <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/files.png" style="width: 50px;height: 50px"/>
              <span>{{ item.questionMaterialName }}</span>
            </div>
          </div>
        </div>
      </div>

      <!--  答案-->
      <div style="margin-top: 1%">
        <div v-for="(item, index) in answers" :key="index" style="margin-top: 1%;background-color: #ffffff">
          <div style="margin-left: 3%;font-family: '苹方', serif;font-weight: bolder;font-size: 1.2rem;padding-top: 0.5%;padding-bottom: 0.5%">
            <div style="margin-bottom: 1%">
              {{ item.answerUser }}:
              <span><el-button size="mini" text="true" @click="reply_answer(item.answerId, 0)">✍回复</el-button></span>
              <span><el-button size="mini" text="true" v-if="item.materialNum !== '0'" @click="materialReply(item.answerId)">🔍查看附件</el-button></span>
              <span><el-button v-if="item.answer_userid === GET_USERID" type="warning" size="mini" @click="delAnswer(item.answerId)">❌ 删除回答&nbsp;&nbsp;&nbsp;</el-button></span>
            </div>
            <div style="margin-left: 1%;margin-bottom: 1%" id="answer">
              {{ item.answer }}
            </div>
          </div>

          <!-- 回复 -->
          <div style="margin-left: 5%;font-family: '苹方', serif;font-weight: bolder;font-size: 1rem;padding-bottom: 1%">
            <div v-for="(item1, index1) in reply[index]" :key="index1">
              <div style="margin-bottom: 1%;margin-left: 2%">
                {{ item1.replyUsername }}
                <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/expert_right.png" style="width: 0.8%;margin-top: 1%;"/>
                {{ item1.replyToName }}:
                <span><el-button size="mini" text="true" v-if="item1.reply_type === '0'" @click="reply_answer(item1.replyId, 1)">✍回复</el-button></span>
                <span><el-button size="mini" text="true" v-if="item1.replyMaterial !== 0" @click="materialReply(item1.replyId)">🔍查看附件</el-button></span>
                <span><el-button v-if="item1.replyUserid === GET_USERID" type="warning" size="mini" @click="delReply(item1.replyId)">❌ 删除回复&nbsp;&nbsp;&nbsp;</el-button></span>
              </div>
              <div style="margin-left: 3%;margin-bottom: 0.5%">
                {{ item1.replyMessage }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--写回答-->
    <div>
      <el-dialog
          v-model="addAnswerDialog"
          title="回答问题"
          width="50%"
          :close-on-click-modal="false"
          :show-close="false"
      >
        <!--弹窗主体-->
        <div>
          <div>
            <div style="border: 1px solid #ccc;">
              <el-input
                  v-model="expert_answer"
                  :autosize="{ minRows: 2, maxRows: 10 }"
                  type="textarea"
                  placeholder="请输入回答"
              />
            </div>
          </div>
          <div style="margin-top: 2%">
            <div>
              <el-upload
                  ref="upload"
                  class="upload-demo"
                  action="https://www.cr12cz.cn/czscsms/up/upload"
                  :auto-upload="false"
                  :data="dataup"
                  :on-change="imgchange"
                  :on-remove="handleRemove"
                  :multiple="false"
                  :on-success="upsuccessAnswer"
              >
                <template #trigger>
                  <el-button type="primary" style="margin-left: 35%">请选择附件</el-button>
                </template>
                <div>
                </div>
              </el-upload>
            </div>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="cancelDia()"> 取消 </el-button>
            <el-button type="primary" @click="addAnswer()"> 提交 </el-button>
          </span>
        </template>
      </el-dialog>
    </div>

    <!--写回复-->
    <div>
      <el-dialog
          v-model="addReplyDialog"
          title="回复"
          width="50%"
          :close-on-click-modal="false"
          :show-close="false"
      >
        <!--弹窗主体-->
        <div>
          <div>
            <div style="border: 1px solid #ccc;">
              <el-input
                  v-model="reply_answer_message"
                  :autosize="{ minRows: 2, maxRows: 10 }"
                  type="textarea"
                  placeholder="请输入回答"
              />
            </div>
          </div>
          <div style="margin-top: 2%">
            <div>
              <el-upload
                  ref="upload"
                  class="upload-demo"
                  action="https://www.cr12cz.cn/czscsms/up/upload"
                  :auto-upload="false"
                  :data="dataup"
                  :on-change="imgchange"
                  :on-remove="handleRemove"
                  :multiple="false"
                  :on-success="upsuccessReply"
              >
                <template #trigger>
                  <el-button type="primary" style="margin-left: 35%">请选择附件</el-button>
                </template>
                <div>
                </div>
              </el-upload>
            </div>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="cancelDia()"> 取消 </el-button>
            <el-button type="primary" @click="addReply()"> 提交 </el-button>
          </span>
        </template>
      </el-dialog>
    </div>

  <!--  附件-->
    <div>
      <el-dialog
          v-model="MaterialyDialog"
          title="附件"
          width="50%"
          :close-on-click-modal="false"
          :show-close="false"
      >
        <!--弹窗主体-->
        <div>
          <div style="margin-top: 1%;margin-left: 3%;display: flex;flex-wrap: wrap">
            <div v-for="item in replyMaterialUrl" style="margin-left: 2%;margin-top: 1%">
              <img v-if="item.replyUrl.includes('jpg')" :src="item.replyUrl"
                   style="width: 300px;height: 300px"/>
              <img v-if="item.replyUrl.includes('png')" :src="item.replyUrl"
                   style="width: 300px;height: 300px"/>
              <video width="300" height="300" controls v-if="item.replyUrl.includes('mp4')">
                <source :src="item.replyUrl" type="video/mp4">
              </video>
              <div
                  v-if="!item.replyUrl.includes('jpg') && !item.replyUrl.includes('png') && !item.replyUrl.includes('mp4')"
                  style="margin-top: 60px"
                  @dblclick="openUrl(item.replyUrl)">
                <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/files.png" style="width: 50px;height: 50px"/>
                <span>{{ item.replyName }}</span>
              </div>
            </div>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="MaterialyDialog = false"> 关闭 </el-button>
          </span>
        </template>
      </el-dialog>
    </div>

  </div>
</template>

<script>
import Header from "@/views/header1";
import {ElMessage, ElMessageBox } from "element-plus";
import {Editor, Toolbar} from "@wangeditor/editor-for-vue";
import { uuid } from '@/utils/time';
import {mapGetters} from "vuex";
import $ from 'jquery'
export default {
  components: {Header, Editor, Toolbar},
  computed: {
    ...mapGetters(['GET_USERID']),
    ...mapGetters(['GET_USER']),
  },
  name: "answer_detail",
  data() {
    return {
      questionTitle: '',
      question: '',
      question_userid: '',
      question_username: '',
      question_time: '',
      question_id: '',
      answers: [],
      reply: [],
      reply_reply: [],
      materialUrl: [],
      addAnswerDialog: false,
      addReplyDialog: false,
      answer: '',
      expertype: '',
      expertypes: [
        {
          value: '0',
          label: '内部专家',
        },
        {
          value: '1',
          label: '外部专家',
        },
        {
          value: '2',
          label: '隧道专家',
        },
        {
          value: '3',
          label: '外单位专家',
        }
      ],
      expert_answer: '',
      dataup: {
        file: '',
        userid: ''
      },
      answerid: '',
      fileList: [],
      reply_answer_message: '',
      reply_id: '',
      reply_replyid: '',
      reply_type: '',
      MaterialyDialog: false,
      materialid: '',
      replyMaterialUrl: []

    }
  },
  created() {
    this.question = this.$route.params.question;
    this.question_username = this.$route.params.questionUser;
    this.question_time = this.$route.params.questionTime;
    this.question_userid = this.$route.params.question_userid;
    this.question_id = this.$route.params.key;
    this.questionTitle = this.$route.params.title;
    $("#question_detail").html(this.question);
    if (this.$route.params.flag === '1') {
      this.getInit();
    }
    this.getMaterial(this.question_id);
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    getMaterial(row) {
      this.questionMaterialDialog = true;
      this.axios.post('/expertQuestionMaterial/getMaterilUrl', (response) => {
        this.materialUrl = response.obj;
      }, {
        uuid: row
      })
    },
    imgchange(file) {
      this.fileList.push(file);
    },
    handleRemove(file) {
      for (let i = 0; i < this.fileList.length; i++) {
        if (this.fileList[i].raw.lastModified === file.raw.lastModified) {
          this.fileList.splice(i, 1)
        }
      }
    },
    upsuccessAnswer(response) {
      this.axios.post("/expertQuestionMaterial/addQuestionMaterial", (responseAdd) => {
        if (responseAdd.obj) {
          this.addAnswerDialog = false;
          ElMessage({
            message: '回答成功!',
            type: 'success',
          })
        }
      }, {
        question_uuid: this.answerid,
        url: response.obj,
        name: response.message
      })
    },
    cancelDia(){
      this.addAnswerDialog = false;
      this.addReplyDialog = false;
      this.expert_answer = '';
    },
    addAnswer(){
      this.dataup.userid = this.GET_USERID;
      this.answerid = uuid();
      this.axios.post('/expertAnswer/submitAnswer', (response) => {
        if (response.obj) {
          if (this.fileList.length > 0) {
            this.expert_answer = '';
            this.dataup.file = [];
            this.fileList = [];
            this.$refs.upload.submit();
          } else {
            this.addAnswerDialog = false;
            this.axios.post('/expertAnswer/getAnsReplyByQuesId', (response) => {
              this.answers = response.obj;
              this.reply = response.obj2;
            }, {
              questionid: this.question_id
            });

            ElMessage({
              message: '回答成功!',
              type: 'success',
            })
          }
        } else {
          ElMessage.error("添加失败，请联系科信部!")
        }
      }, {
        answer: this.expert_answer,
        questionid: this.question_id,
        id: this.answerid,
        username: this.GET_USER,
        userid: this.GET_USERID,
        materialnum: this.fileList.length
      })
    },
    reply_answer(item, type){
      this.addReplyDialog = true;
      this.reply_replyid = item;
      this.reply_type = type;
    },
    addReply(){
      this.dataup.userid = this.GET_USERID;
      this.reply_id = uuid();
      this.axios.post('/answerReply/saveAnswerReply', (response) => {
        if (response.obj) {
          if (this.fileList.length > 0) {
            this.reply_answer_message = '';
            this.dataup.file = [];
            this.fileList = [];
            this.$refs.upload.submit();
          } else {
            this.addReplyDialog = false;
            this.axios.post('/expertAnswer/getAnsReplyByQuesId', (response) => {
              this.answers = response.obj;
              this.reply = response.obj2;
            }, {
              questionid: this.question_id
            });
            ElMessage({
              message: '回复成功!',
              type: 'success',
            })
          }
        } else {
          ElMessage.error("添加失败，请联系科信部!")
        }
      }, {
        uuid: this.reply_id,
        answerid: this.reply_replyid,
        reply: this.reply_answer_message,
        replyType: this.reply_type,
        username: this.GET_USER,
        userid: this.GET_USERID,
        materilanum: this.fileList.length,
      })
    },
    materialReply(item){
      this.materialid = item;
      this.MaterialyDialog = true;
      this.axios.post('/replyMaterialUrl/getMaterialByRepId', (response) => {
        this.replyMaterialUrl = response.obj;
        console.log(this.materialUrl)
      },{
        reply_id: item
      })
    },
    upsuccessReply(response){
      this.axios.post("/expertQuestionMaterial/addQuestionMaterial", (responseAdd) => {
        if (responseAdd.obj) {
          this.addReplyDialog = false;
          ElMessage({
            message: '回答成功!',
            type: 'success',
          })
        }
      }, {
        reply_uuid: this.reply_id,
        url: response.obj,
        name: response.message
      })
    },
    getInit(){
      this.axios.post('/expertAnswer/getAnsReplyByQuesId', (response) => {
        this.answers = response.obj;
        this.reply = response.obj2;
      }, {
        questionid: this.question_id
      })
    },
    delQuestion(){
      ElMessageBox.confirm(
          '是否确认删除此问题',
          '删除确认',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
          }
      )
          .then(() => {
            this.axios.post('/expertQuestion/delQuestionById', (response) => {
              if (response.obj){
                ElMessage({
                  type: 'success',
                  message: '删除成功',
                });
                this.$router.push({
                  name: 'Expert_index',
                  params: {
                    key: 1,
                  }
                })
              }
            },{
              id: this.question_id
            })
          })
          .catch(() => {

          })
    },
    delAnswer(item){
      ElMessageBox.confirm(
          '是否确认删除此回答',
          '删除确认',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
          }
      )
          .then(() => {
            this.axios.post('/expertAnswer/delAnswerById', (response) => {
              if (response.obj){
                ElMessage({
                  type: 'success',
                  message: '删除成功',
                });
                this.getInit();
              }
            },{
              id: item
            })
          })
          .catch(() => {

          })
    },
    delReply(item){
      ElMessageBox.confirm(
          '是否确认删除此回复',
          '删除确认',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
          }
      )
          .then(() => {
            this.axios.post('/answerReply/delReplyById', (response) => {
              if (response.obj){
                ElMessage({
                  type: 'success',
                  message: '删除成功',
                });
                this.getInit();
              }
            },{
              id: item
            })
          })
          .catch(() => {

          })
    },
    openUrl(val){
      window.open(val, "_blank");
    },

  }
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
#admindiv1 {
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: 95%;
  height: 40px;
  margin: 10px auto 30px;
  justify-content: space-between;
}
</style>